import React, { useContext, useEffect, useState } from "react"
import AuthContext from '../../context/AuthProvider';
import { validateEmail, validatePhoneNumber } from '../../helpers/general'
import { bcApi } from '../../helpers/bigcommerce'

import AccountPageWrapper from "../../components/organisms/AccountPageWrapper/AccountPageWrapper"
import Button from "../../components/atoms/Button/Button"

/* Enable for Klavyio integration */
// import { getListsByEmail, subscribe, unsubscribe } from '../../helpers/klaviyo';
// import Loader from '../../components/atoms/Loader/Loader';
// import Dialog from "../../components/atoms/Dialog/Dialog"

import * as styles from './account.module.css';

const Account = () => {
    const auth = useContext(AuthContext);
    const checkPassword = auth && auth.checkPassword;
    const requiredFields = {
        'first_name': 'Name',
        'last_name': 'Surname',
        'email': 'Email'
    }
    const defaultFields = {
        first_name: '',
        last_name: '',
        phone: '',
        company: '',
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
        authentication: {new_password: ''},
    }
    const [accountDetails, setAccountDetails] = useState(defaultFields);
    const [fieldErrors, setFieldErrors] = useState({});
    const [editing, toggleEditing] = useState(false);
    const [defaultSet, toggleDefaultSet] = useState(false);

    /* Enable if you have custom fields for a customer */
    // const [customDetails, setCustomDetails] = useState(customFields);

    /* Enable for Klavyio integration */
    // const [emailLists, setEmailLists] = useState(false);
    // const [fetchingLists, setFetchingLists] = useState(false);
    // const [subscribed, setSubscribed] = useState({});
    // const [dialogMessage, setDialogMessage] = useState(false);

    useEffect(() => {
        if (!defaultSet && (auth && 'state' in auth && 'object' in auth.state)) {
            setAccountDetails({
                ...accountDetails, 
                ...{
                    first_name: auth.state.object.first_name,
                    last_name: auth.state.object.last_name,
                    phone: auth.state.object.phone,
                    company: auth.state.object.company,
                    email: auth.state.object.email,
                }
            });

            /* Enable if you have custom fields for a customer */
            // const exampleCustomField = auth.state.object.form_fields?.filter(formField => formField.name === 'Custom Field');
            // setCustomDetails({
            //     ...customDetails,
            //     ...{
            //     'Custom Field': (exampleCustomField && exampleCustomField.length > 0) ? exampleCustomField[0].value : '',
            //     }
            // })

            toggleDefaultSet(true);
        }
    }, [auth, accountDetails, defaultSet, toggleDefaultSet]); // customDetails

    /* Enable for Klavyio integration */
    // useEffect(() => {
    //     if (!emailLists && !fetchingLists && accountDetails.email !== '') {
    //       setFetchingLists(true);
    //       getListsByEmail(accountDetails.email).then(emailLists => {
    //         setEmailLists(emailLists);
    //         const subscribedData = {};
    //         emailLists.map(list => {
    //           subscribedData[list.list.list_id] = list.subscribed.response.length > 0;
    //           return true;
    //         });
    //         setSubscribed(subscribedData);
    //         setFetchingLists(false);
    //       });
    //     }
    //   }, [accountDetails, emailLists, fetchingLists]);
    
    //   const toggleSubscription = (e, listId) => {
    //     const checked = e.target.checked;
    //     const parentElement = e.target.parentNode;
    //     parentElement.classList.add(styles.loading);
    //     const complete = new Promise((res) => {
    //       if (checked) {
    //         // subscribe to list
    //         subscribe(listId, accountDetails.email).then(response => {
    //           // console.log('Subscribed to ', listId);
    //           setDialogMessage(true);
    //           res(true);
    //         });
    //       } else {
    //         // unsubscribe from list
    //         unsubscribe(listId, accountDetails.email).then(response => {
    //           // console.log('Unsubscribed from ', listId);
    //           res(true);
    //         });
    //       }
    //     });
    
    //     complete.then(() => {
    //       const subscribedData = {...subscribed};
    //       subscribedData[listId] = checked;
    //       parentElement.classList.remove(styles.loading);
    //       setSubscribed(subscribedData);
    //     });
    //   }
    
    //   const clearDialog = () => {
    //     setDialogMessage(false);
    //   }
    /* END Klavyio integration code */

    const saveDetails = async () => {
        const promises = [];
        let valid = true;
        const errors = {}
        setFieldErrors({})

        // Check if required fields are not blank
        Object.keys(requiredFields).map((field) => {
            if (accountDetails[field] === '') {
                valid = false;
                errors[field] = `${requiredFields[field]} can not be blank`
            }

            return true;
        })

        // Validate the email address
        if (!validateEmail(accountDetails.email)) {
            valid = false;
            errors.email = 'This email address is invalid'
        }

        // Validate the phone number
        if (accountDetails.phone && !validatePhoneNumber(accountDetails.phone)) {
            valid = false;
            errors.phone = 'Provide a valid phone number'
        }

        // Check if password is set to change and matches
        if (accountDetails.new_password !== '') {
            if (accountDetails.new_password !== accountDetails.confirm_password) {
                valid = false;
                errors.new_password = `Passwords do not match`
            }

            if (accountDetails.current_password === '') {
                valid = false;
                errors.current_password = `Current password is required to set new password`
            }

            if (accountDetails.current_password !== '') {
                promises.push(new Promise(res => {
                    checkPassword(auth.state.object.email, accountDetails.current_password).then(response => {
                        if ('errors' in response) {
                            valid = false;
                            errors.current_password = `Current password is not correct`
                        }
                        res(true)
                        return true;
                    });
                }))
            }
        }

        Promise.all(promises).then(() => {
            if (valid) {
                // Run process to save details
                const fields = accountDetails;
                if (accountDetails.new_password !== '') {
                    fields.authentication.new_password = accountDetails.new_password;
                }
                delete fields.current_password;
                delete fields.new_password;
                delete fields.confirm_password;
                fields.id = auth.state.customerId;

                /* Enable if you have custom fields for a customer */
                // const cFields = Object.keys(customDetails).map(customField => {
                //     return {
                //         name: customField,
                //         value: customDetails[customField],
                //         customer_id: auth.state.customerId,
                //     }
                // });

                bcApi('customers', 'PUT', [fields]).then(response => {
                    setAccountDetails({...defaultFields, ...fields});
                    /* Enable if you have custom fields for a customer */
                    // bcApi('customers/form-field-values', 'PUT', cFields).then(response => {
                    toggleEditing(!editing);
                    // });
                })
            } else {
                setFieldErrors(errors)
            }
        })
    }

    const itemClasses = editing
        ? `${styles.details} ${styles.edit}`
        : `${styles.details} ${styles.view}`;
    const inputClasses = `formField mb-0 ${!editing ? 'hidden' : 'show'}`;
    const labelClasses = editing ? 'hidden' : `show ${styles.label}`;

    return (
        <div>
        <div className={`${styles.container}`}>
            <div>
            <p className="subtitle">&nbsp;</p>
            <div className={itemClasses}>
                <span>Name:</span>
                <div>
                <div className={labelClasses}>{accountDetails.first_name}</div>
                <div className={inputClasses}>
                    <input
                    type="text"
                    name="name"
                    defaultValue={accountDetails.first_name}
                    onChange={e =>
                        setAccountDetails({
                        ...accountDetails,
                        first_name: e.target.value
                        })
                    }
                    />
                    {fieldErrors && 'first_name' in fieldErrors && (
                    <span className="error">{fieldErrors.first_name}</span>
                    )}
                </div>
                </div>
            </div>
            <div className={itemClasses}>
                <span>Surname:</span>
                <div>
                <div className={labelClasses}>{accountDetails.last_name}</div>
                <div className={inputClasses}>
                    <input
                    name="surname"
                    type="text"
                    defaultValue={accountDetails.last_name}
                    onChange={e =>
                        setAccountDetails({
                        ...accountDetails,
                        last_name: e.target.value
                        })
                    }
                    />
                    {fieldErrors && 'last_name' in fieldErrors && (
                    <span className="error">{fieldErrors.last_name}</span>
                    )}
                </div>
                </div>
            </div>
            {(accountDetails.phone || editing) && (
                <div className={itemClasses}>
                <span>Phone:</span>
                <div>
                    <div className={labelClasses}>{accountDetails.phone}</div>
                    <div className={inputClasses}>
                    <input
                        type="text"
                        name="phone"
                        defaultValue={accountDetails.phone}
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            phone: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'phone' in fieldErrors && (
                    <span className="error">{fieldErrors.phone}</span>
                    )}
                    </div>
                </div>
                </div>
            )}
            {(accountDetails.company || editing) && (
                <div className={itemClasses}>
                <span>Company:</span>
                <div>
                    <div className={labelClasses}>{accountDetails.company}</div>
                    <div className={inputClasses}>
                    <input
                        type="text"
                        name="company"
                        defaultValue={accountDetails.company}
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            company: e.target.value
                        })
                        }
                    />
                    </div>
                </div>
                </div>
            )}
            {/* Enable if you have custom fields for a customer
            (customDetails['Custom Field'] || editing) && (
                <div className={itemClasses}>
                <span>Custom Field:</span>
                <div>
                    <div className={labelClasses}>{customDetails['Custom Field']}</div>
                    <div className={inputClasses}>
                    <input
                        type="text"
                        name="custom_field"
                        defaultValue={customDetails['Custom Field']}
                        onChange={e => 
                        setCustomDetails({
                            ...customDetails,
                            'Custom Field': e.target.value,
                        })
                        }
                    />
                    </div>
                </div>
                </div>
            )*/}
            </div>
            <div>
            <p className="subtitle">Log in details</p>
            <div className={itemClasses}>
                <span>Email:</span>
                <div>
                <div className={labelClasses}>{accountDetails.email}</div>
                <div className={inputClasses}>
                    <input
                    type="email"
                    defaultValue={accountDetails.email}
                    onChange={e =>
                        setAccountDetails({
                        ...accountDetails,
                        email: e.target.value
                        })
                    }
                    />
                    {fieldErrors && 'email' in fieldErrors && (
                    <span className="error">{fieldErrors.email}</span>
                    )}
                </div>
                </div>
            </div>
            <div className={`${itemClasses} ${styles.password}`}>
                <span>Password:</span>
                <div>
                <div className={labelClasses}>********</div>
                <div className={!editing ? 'hidden' : 'show'}>
                    <div className="formField">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        id="newPassword"
                        autoComplete="new-password"
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            new_password: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'new_password' in fieldErrors && (
                        <span className="error">{fieldErrors.new_password}</span>
                    )}
                    </div>
                    <div className="formField">
                    <label htmlFor="confirmPassword">Confirm Password</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            confirm_password: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'new_password' in fieldErrors && (
                        <span className="error">{fieldErrors.new_password}</span>
                    )}
                    </div>
                    <div
                    className={`formField ${
                        accountDetails.new_password === '' ? 'hidden' : 'show'
                    }`}
                    >
                    <label htmlFor="currentPassword">Current Password</label>
                    <input
                        type="password"
                        id="currentPassword"
                        onChange={e =>
                        setAccountDetails({
                            ...accountDetails,
                            current_password: e.target.value
                        })
                        }
                    />
                    {fieldErrors && 'current_password' in fieldErrors && (
                        <span className="error">
                        {fieldErrors.current_password}
                        </span>
                    )}
                    </div>
                </div>
                </div>
            </div>

            {/* Enable for Klavyio integration 
            <div className={styles.emailPreferences}>
                <p className="subtitle">Email Preferences</p>
                <Dialog open={dialogMessage ? true : false} title="Confirm subscription" size="sm" hideBtnCancel disableBackdropClick onOk={() => clearDialog()}>Please check your email to confirm your subscription to the selected list.</Dialog>
                {!emailLists && (
                <>
                    Fetching preferences...
                </>
                )}

                {emailLists && (
                <ul className={styles.emailLists}>
                    {emailLists.map((list, listIndex) => {
                    const listName = list.list.list_name;
                    const listId = list.list.list_id;

                    return (
                        <li key={listIndex}>
                        <div className={styles.emailCheckbox}>
                            <Loader />
                            <input type="checkbox" id={listId} checked={subscribed[listId] ? 'checked' : ''} onChange={(e) => toggleSubscription(e, listId)} />
                        </div>
                        <label htmlFor={listId} className={styles.emailListName}>{listName}</label>
                        </li>
                    )
                    })}
                </ul>
                )}
            </div> */}
            </div>
        </div>
        <div className={styles.btnGroup}>
            <Button
            type="span"
            level="primary"
            onClick={
                !editing ? () => toggleEditing(!editing) : () => saveDetails()
            }
            >
            {!editing ? 'Update details' : 'Save details'}
            </Button>
            <Button
            type="span"
            level="secondary"
            className={!editing ? 'hidden' : 'show'}
            onClick={() => toggleEditing(!editing)}
            >
            Cancel update
            </Button>
        </div>
        </div>
    );
}

const AccountOutput = () => (
    <AccountPageWrapper metaTitle="Account - My Details" title="My Details">
        <Account />
    </AccountPageWrapper>
)

export default AccountOutput